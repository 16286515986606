import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../../components/layout';
// import LoginPage from '../login';
import SEO from '../../components/seo-component/seo';
import HomeOurUnique from '../../components/HomeOurUnique';
import HomeQuoteSlider from '../../components/HomeQuoteSlider';
import HomeOurService from '../../components/HomeOurService';
import HomeOurJewels from '../../components/HomeOurJewels';
import HomeOurProud from '../../components/HomeOurProud';
import HomeHeader from '../../components/HomeHeader';
import HomeIntro from '../../components/HomeIntro';
import HomeOurThoughts from '../../components/HomeOurThoughts';
import LetsTalk from '../../components/LetsTalk';
import HomeQuoteSliderRight from '../../components/HomeQuoteSliderRight';
import { themeStyles } from '../../styles';
const { langs } = require('../../../languages');
import clsx from 'clsx';

export default function Home({ location, pageContext, data }) {
  const classes = themeStyles(data);
  const [user, setUser] = useState(false);

  const allFooterData = [
    data.allContentfulFooterMenu.nodes[0],
    data.allContentfulSiteFooter.nodes[0],
  ];
  // const loginUser = () => {
  //   localStorage.setItem('user', JSON.stringify(true));
  //   window.location.reload();
  // };

  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  const capitalizeKey = (key) =>
    key
      .split('_')
      .map((word, index) => (index ? word.charAt(0).toUpperCase() + word.slice(1) : word))
      .join('');

  const getQueryParams = (search) =>
    decodeURI(search)
      .replace('?', '')
      .split('&')
      .map((param) => param.split('='))
      .reduce((values, [key, value]) => {
        if (key) {
          if (
            key == 'campaign_operator' ||
            key == 'campaignoperator' ||
            key == 'utm_campaignoperator'
          ) {
            key = 'utm_campaign_operator';
          } else if (
            key == 'master_campaign' ||
            key == 'utm_mastercampaign' ||
            key == 'mastercampaign'
          ) {
            key = 'utm_master_campaign';
          }
          values[capitalizeKey(key)] = value;
        }
        return values;
      }, {});

  useEffect(() => {
    const getUTM = JSON.parse(localStorage.getItem('utm'));

    const urlsParams = getQueryParams(location.search);
    if (urlsParams && Object.keys(urlsParams).length) {
      if (JSON.stringify(getUTM) !== JSON.stringify(urlsParams)) {
        localStorage.setItem('utm', JSON.stringify(urlsParams));
      }
    }
  }, [location.search]);

  // useEffect(() => {
  //   const getUser = JSON.parse(localStorage.getItem('user'));
  //   setUser(getUser);
  // }, [user]);
  const homePageData = data?.contentfulPartnerSite?.homePage;
  return (
    <>
      {/* {user ? ( */}
        <Layout
          partnerCode={pageContext.partnerCode}
          navigation={data.contentfulPartnerSite}
          styles={data.contentfulPartnerTheme}
          footer={allFooterData}
          metadata={data.contentfulPartnerTheme.partnerMetaData}
          logout={logoutUser}
          siteType={data.contentfulPartnerSite.siteType}
          searchEnabled={data.contentfulPartnerSite.enableSearch}
          astEnabled={data.contentfulPartnerSite.hasAgentSelectionToolAstRequired}
          basePath={langs[0].basePath}
          bucketName={data?.contentfulPartnerSite?.adminDetails?.s3BucketName}
          location={location}
        >
          <SEO
            data={data?.contentfulPartnerSite?.homePage?.seoEntry}
            bucketName={data?.contentfulPartnerSite?.adminDetails?.s3BucketName}
            basePath={langs[0].basePath}
            organizationName={data?.contentfulPartnerSite?.organizationName}
            organizationLogo={data?.contentfulPartnerSite?.organizationLogo?.file?.url}
            organizationLinkedinLink={data?.contentfulPartnerSite?.organizationLinkedinLink}
            organizationFacebookLink={data?.contentfulPartnerSite?.organizationFacebookLink}
            organizationYoutubeLink={data?.contentfulPartnerSite?.organizationYoutubeLink}
            forPage="HomePage"
            location={location}
          />
          <div className="home-main-block">
            <div className={clsx('home-dotmatrix', classes['home-dotmat-cls'])}>
              <HomeHeader
                title={homePageData?.homePageHeaderSection?.header}
                subtitle={homePageData?.homePageHeaderSection?.subHeader}
                description={documentToReactComponents(
                  JSON.parse(homePageData?.homePageHeaderSection.shortDescription.raw)
                )}
                heroImage={homePageData?.homePageHeroImage?.file?.url + '?fm=webp&q=100'}
                heroImageAlt={homePageData?.homePageHeroImage?.description}
                button={homePageData?.homePageHeaderSection?.ctaButton?.link}
                data={data}
                basePath={langs[0].basePath}
              />
            </div>
            <HomeIntro
              headerIntroTitle={homePageData?.homePageIntroSection?.header}
              headerIntroSubtitle={homePageData?.homePageIntroSection?.subHeader}
              headerIntroImage={
                homePageData?.homePageIntroSection?.image?.file?.url + '?fm=webp&q=100'
              }
              headerIntroImageAlt={homePageData?.homePageIntroSection?.image?.description}
              headerIntroDescription={documentToReactComponents(
                JSON.parse(homePageData?.homePageIntroSection.shortDescription.raw)
              )}
              introSectionTopic={homePageData?.homePageIntroSectionTopic}
              introSectionDescription={homePageData?.homePageIntroSectionDescription}
              isVideo={homePageData?.homePageIntroSection?.isVideo}
              videoComponent={homePageData?.homePageIntroSection?.videoComponent}
              data={data}
            />

            <div className={classes['bg-donuts']}>
              <HomeOurUnique
                list={data?.contentfulPartnerSite?.homePage?.homePageExpertiseSection}
                titleAndImage={
                  data?.contentfulPartnerSite?.homePage?.homePageExpertiseTitleAndImage
                }
                data={data.contentfulPartnerSite.homePage}
                backgroundImg={data?.contentfulComponentBackgroundImages}
                loadMoreButton={data?.contentfulPartnerSite?.homePage?.expertiseSectionCtaButton}
                basePath={langs[0].basePath}
                autoRotateEnabled={data?.contentfulPartnerSite?.homePage?.homePageExpertiseAutoRotate}
              />
              <HomeQuoteSlider
                data={data?.contentfulPartnerSite?.homePage?.homePageCustomerTestimonials}
                backgroundImg={data?.contentfulComponentBackgroundImages}
                autoRotateEnabled={data?.contentfulPartnerSite?.homePage?.homePageCustomerTestimonialsAutoRotate}
              />
              <HomeOurService data={data.contentfulPartnerSite.homePage} basePath={langs[0].basePath} />
            </div>
            <HomeOurProud
              title={data?.contentfulPartnerSite?.homePage?.homePageProudAssociationTitle}
              logos={data?.contentfulPartnerSite?.homePage?.homePageProudAssociationLogos}
              backgroundImg={data?.contentfulComponentBackgroundImages}
              subTitle={data?.contentfulPartnerSite?.homePage?.homePageProudAssociationSubtitle}
              bodyCopy={data?.contentfulPartnerSite?.homePage?.bodyCopy}
              autoRotateEnabled={data?.contentfulPartnerSite?.homePage?.homePageProudAutoRotate}
              />
            <div className={classes['bg-donuts-right']}>
              <HomeOurThoughts
                data={data.contentfulPartnerSite.homePage}
                blogData={data?.allContentfulBlogs?.nodes}
                basePath={langs[0].basePath}
                backgroundImg={data?.contentfulComponentBackgroundImages}
                autoRotateEnabled={data?.contentfulPartnerSite?.homePage?.homePageThoughtsAutoRotate}
                />
              <HomeOurJewels
                header={data?.contentfulPartnerSite?.homePage?.awardsAndRecognitionHeader}
                subHeader={data?.contentfulPartnerSite?.homePage?.awardsAndRecognitionSubHeader}
                image={data?.contentfulPartnerSite?.homePage?.homePageAwardsAndRecognitionImages}
                backgroundImg={data?.contentfulComponentBackgroundImages}
                autoRotateEnabled={data?.contentfulPartnerSite?.homePage?.awardsAndRecognitionAutoRotate}
              />
              <HomeQuoteSliderRight
                data={data?.contentfulPartnerSite?.homePage?.homePageCustomerTestimonial}
                backgroundImg={data?.contentfulComponentBackgroundImages}
                autoRotateEnabled={data?.contentfulPartnerSite?.homePage?.homePageCustomerTestimonialAutoRotate}
              />
              <LetsTalk
                title={data?.contentfulPartnerSite?.homePage?.homePageLetsTalkSectionTitle}
                image={data?.contentfulPartnerSite?.homePage?.homePageLetsTalkBackgroundImage}
                LetsTalkButton={data?.contentfulPartnerSite?.homePage?.letsTalkButtonLink}
                LetsTalktitle="homePageLetsTalkSectionTitle"
                LetsTalkBackgroundImage="homePageLetsTalkBackgroundImage"
                ButtonLabel="buttonLabel"
                basePath={langs[0]?.basePath}
              />
            </div>
          </div>
        </Layout>
      {/* ) : (
        <LoginPage isLogin={user} login={loginUser} />
      )} */}
    </>
  );
}

export const query = graphql`
  query homepageDataEN($partnerCode: String!) {
    contentfulComponentBackgroundImages(partnerId: { eq: $partnerCode }) {
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
    }
    allContentfulFooterMenu {
      nodes {
        menuTitle1
        menuTitle2
        menuTitle3
        menuTitle4
        menuTitle5
        slugMenuTitle1
        slugMenuTitle2
        slugMenuTitle3
        slugMenuTitle4
        slugMenuTitle5
        aboutUsMenu {
          slug
          createdAt
          anchorLink
          label
        }
        solutionsMenu {
          slug
          createdAt
          anchorLink
          label
        }
        insightsMenu {
          slug
          createdAt
          anchorLink
          label
        }
        technologyMenu {
          slug
          createdAt
          anchorLink
          label
        }
        whyCartusMenu {
          slug
          label
          pdf {
            description
            file {
              url
            }
          }
          linkOrPdf
        }
        copyright
        goToTopImage {
          description
          file {
            url
          }
        }
        footerLogo {
          title
          description
          file {
            fileName
            url
          }
        }
        lgBigdonutImage {
          description
          file {
            url
          }
        }
        lgSmalldonutImage {
          description
          file {
            url
          }
        }
        mdBigdonutImage {
          description
          file {
            url
          }
        }
        mdSmalldonutImage {
          description
          file {
            url
          }
        }
        smBigdonutImage {
          description
          file {
            url
          }
        }
        smSmalldonutImage {
          description
          file {
            url
          }
        }
        xsBigdonutImage {
          description
          file {
            url
          }
        }
        xsSmalldonutImage {
          description
          file {
            url
          }
        }
      }
    }

    allContentfulSiteFooter {
      nodes {
        contactUsButton {
          label
          link {
            ... on ContentfulInternalLink {
              id
              slug
            }
            ... on ContentfulWebLink {
              id
              url
            }
          }
        }
        footerLinks {
          label
          id
          link {
            ... on ContentfulWebLink {
              url
              openInANewTab
            }
            ... on ContentfulComponentPdfWithUrl {
              pdfOrUrl
              url
              pdf {
                description
                file {
                  url
                  fileName
                }
              }
            }
            ... on ContentfulInternalLink {
              pageName
              slug
              createdAt
              anchorLink
            }
          }
        }
        rssFeedIcon {
          title
          description
          file {
            url
          }
        }
        socialMediaIcons {
          title
          description
          id
          file {
            fileName
            url
          }
        }
      }
    }

    allContentfulBlogs(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        id
        node_locale
        entryTitle
        contentful_id
        blogSlug
        featured
        blogType
        news
        blogContent {
          ... on ContentfulShortBlog {
            id
            dontDisplayPublishedDateInBlogLandingTile
            dateOfPublication(locale: "US")
            shortBlogTitle
            thumbnailImage {
              description
              file {
                url
              }
            }
            description {
              raw
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulLongBlog {
            id
            dontDisplayPublishedDateInBlogLandingTile
            longBlogTitle
            dateOfPublication(locale: "US")
            thumbnailImage {
              description
              file {
                url
              }
            }
            description {
              raw
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulPodcastBlog {
            id
            dontDisplayPublishedDateInBlogLandingTile
            dateOfPublication(locale: "US")
            podcastBlogTitle
            description {
              raw
            }
            podcastBlogTileImage {
              description
              file {
                url
              }
              title
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }

    contentfulPartnerSite(partnerId: { eq: $partnerCode }) {
      entryTitle
      partnerId
      programName
      enableSearch
      hasAgentSelectionToolAstRequired
      siteType
      adminDetails {
        s3BucketName
        s3BucketSubFolder
      }
      navigation {
        primaryLogoHeader {
          description
          title
          file {
            url
            fileName
          }
        }
        donutNavigationBackground {
          description
          file {
            url
          }
        }
        donutNavigationBackgroundImage {
          description
          file {
            url
          }
        }
        searchIconBlue {
          file {
            url
          }
          description
        }
        searchIconWhite {
          file {
            url
          }
          description
        }
        secondaryNavigation {
          id
          menuLabel
          isInternalLink
          placeHolder
          menuItems {
            entryTitle
            label
            link {
              ... on ContentfulWebLink {
                id
                url
                openInANewTab
              }
              ... on ContentfulInternalLink {
                id
                slug
              }
            }
          }
        }
        menus {
          id
          menuLabel
          isInternalLink
          menuItems {
            entryTitle
            label
            link {
              ... on ContentfulInternalLink {
                slug
                pageName
                referenceToPage {
                  ... on ContentfulContactUs {
                    id
                    contentful_id
                  }
                  ... on ContentfulPageTemplate {
                    id
                    contentful_id
                  }
                }
                entryTitle
              }
            }
          }
          menuLink {
            link {
              ... on ContentfulInternalLink {
                slug
                pageName
                referenceToPage {
                  ... on ContentfulContactUs {
                    id
                    contentful_id
                  }
                  ... on ContentfulPageTemplate {
                    id
                    contentful_id
                  }
                }
              }
              ... on ContentfulWebLink {
                id
                url
                openInANewTab
              }
            }
          }
        }
        buttonAction {
          entryTitle
          id
          contentful_id
          label
        }
      }
      homePage {
        awardsAndRecognitionAutoRotate
        homePageCustomerTestimonialAutoRotate
        homePageThoughtsAutoRotate
        homePageExpertiseAutoRotate
        homePageProudAutoRotate
        homePageCustomerTestimonialsAutoRotate
        ... on ContentfulHomePage {
          entryTitle
          bodyCopy
          seoEntry {
            altimageTag
            ogImage {
              description
              file {
                url
              }
            }
            ogSiteName
            ogType
            metaDescription {
              metaDescription
            }
            pageTitle
            metaKeywords
            metaRobotsTag
            defaultOgLocale
          }
          homePageIntroSectionTopic
          homePageIntroSectionDescription
          customerTestimonialsDotMatrixImage {
            description
            file {
              url
            }
          }
          gridBrightBlueBackgroundImage {
            description
            file {
              url
            }
          }
          donutSliderBackgroundImage {
            description
            file {
              url
            }
          }
          dotMatrixBackgroundImage {
            description
            title
            file {
              url
            }
          }
          homePageIntroSection {
            header
            subHeader
            isVideo
            videoComponent {
              ...compVideo
            }
            shortDescription {
              raw
            }
            image {
              description
              title
              file {
                url
              }
            }
            ctaButton {
              buttonLabel
              link {
                link {
                  ... on ContentfulWebLink {
                    id
                    url
                  }
                }
              }
            }
          }
          homePageHeaderSection {
            subHeader
            shortDescription {
              raw
            }
            header
            image {
              description
              file {
                url
              }
            }
            ctaButton {
              link {
                label
                link {
                  ... on ContentfulInternalLink {
                    slug
                  }
                  ... on ContentfulWebLink {
                    url
                  }
                }
              }
            }
          }

          homePageHeroImage {
            description
            title
            file {
              url
            }
          }
          headerDonutImage {
            file {
              url
            }
          }
          headerDotMatrixImage {
            file {
              url
            }
          }
          top
          homePageLetsTalkBackgroundImage {
            description
            file {
              url
            }
          }
          homePageLetsTalkSectionTitle {
            raw
          }
          letsTalkButtonLink {
            label
            link {
              ... on ContentfulInternalLink {
                slug
              }
              ... on ContentfulWebLink {
                url
              }
            }
          }
          homePageCustomerTestimonials {
            authorName
            authorDescription {
              raw
            }
            secondaryImage {
              description
              file {
                url
              }
            }
            authorDesignation
            authorImage {
              description
              title
              file {
                url
              }
            }
          }
          donutRightBackgroundImage {
            description
            file {
              url
            }
          }
          donutBackgroundImage {
            description
            file {
              url
            }
          }
          dotMatrixBackgroundImage {
            description
            file {
              url
            }
          }
          capsulesBackgroundImageSmalldevices {
            description
            file {
              url
            }
          }
          homePageCustomerTestimonial {
            authorDesignation
            authorImage {
              description
              title
              file {
                url
              }
            }
            secondaryImage {
              description
              file {
                url
              }
            }
            authorDescription {
              raw
            }
            authorName
          }

          sys {
            contentType {
              sys {
                id
              }
            }
          }
          dotMatrixBackgroundImage {
            description
            file {
              url
            }
          }
          capsulesBackgroundImage {
            description
            file {
              url
            }
          }
          homePageServiceTextSection {
            header
            subHeader
            shortDescription {
              raw
            }
            ctaButton {
              buttonLabel
              internalLink {
                slug
              }
            }
          }
          homePageServicesTileSection {
            backgroundImage {
              file {
                url
              }
              description
            }
            secondaryImage {
              file {
                url
              }
              description
            }
            linkOrPdf
            pdfOrDocument {
              file {
                url
              }
            }
            header {
              raw
            }
            tileLink {
              anchorLink
              slug
            }
          }
          thoughtsSectionCtaButtonLink {
            label
            link {
              ... on ContentfulInternalLink {
                slug
              }
              ... on ContentfulWebLink {
                url
              }
            }
          }
          homePageThoughtsSection {
            title
            subTitle
            arrowImage {
              description
              file {
                url
              }
              title
            }
          }
          dotMatrixBackgroundImage {
            description
            file {
              url
            }
          }
          capsulesBackgroundImage {
            description
            file {
              url
            }
          }
          homePageAwardsAndRecognitionImages {
            description
            file {
              url
            }
            title
          }
          awardsAndRecognitionHeader
          awardsAndRecognitionSubHeader
          homePageProudAssociationTitle
          homePageProudAssociationSubtitle
          homePageProudAssociationLogos {
            description
            title
            file {
              url
            }
          }
          expertiseSectionCtaButton {
            internalLink {
              slug
            }
            buttonLabel
          }
          homePageExpertiseTitleAndImage {
            title
            arrowImage {
              description
              file {
                url
              }
            }
          }
          homePageExpertiseSection {
            backgroundImage {
              description
              file {
                url
              }
            }
            linkOrPdf
            pdfOrDocument {
              description
              file {
                url
              }
            }
            header {
              raw
            }
            tileLink {
              anchorLink
              slug
            }
          }

          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
      organizationName
      organizationLogo {
        file {
          fileName
          url
        }
      }
      organizationLinkedinLink
      organizationFacebookLink
      organizationYoutubeLink
    }

    contentfulPartnerTheme(partnerId: { eq: $partnerCode }) {
      entryTitle
      contentful_id
      buttonStyle {
        boxShadow
        buttonBackgroundColor
        entryTitle
        buttonType
        buttonFontSize
        boxShadowBlur
        boxShadowColor
        boxShadowHorizontalPosition
        boxShadowVerticalPosition
        buttonFontFamily
        googleFontFamily
      }
      entryTitle
      partnerId
      googleTagManagerId
      backgroud {
        contentful_id
        entryTitle
        bodyBackgroundColor
        footerBackgroundColor
        progressBarBackgroundColor
        progressBarBackgroundColorSecondary
      }
      grid {
        contentful_id
        gridSpacing
        gridJustifyContent
        gridAlignments
      }
      typography {
        bodyFontSize
        bodyTextColor
        contentful_id
        entryTitle
        headingColor
        headingFontFamily
        googleFontBody
        googleFontHeading
        bodyFontFamily
        headingFontSize
        h3
        h4
        h5
        h6
        headingLetterSpacing
        headingLineHeight
        linkTextColor
        footerFontSize
        footerTextColor
        footerLinkColor
        svgIconColor
      }
      partnerMetaData {
        clientNumber
        entryTitle
        hesAstBuyCode
        hesAstBuySellCode
        hesAstSellCode
        leadSource
        appType
        partner
        processor
      }
    }
  }

  fragment contentfulBuySellOption on ContentfulBuySellOption {
    label
    image {
      description
      file {
        url
      }
    }
    hasBuy
    hasSell
    backgroundColorOfTheIconWhenSelected
  }

  fragment compVideo on ContentfulCustomVideo {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    entryTitle
    videoComponent {
      entryTitle
      videoType
      videoId
      videoTitle
      dateOfPublishing
    }
  }
`;
